import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { Platform, ToastController} from "@ionic/angular";
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from "@capacitor/push-notifications";
import { EnvService } from "./env.service";
import { from } from "rxjs";
import {Preferences} from "@capacitor/preferences";
//import { Storage } from "@capacitor/storage";


@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(
      private router: Router,
      private plt: Platform,
      private env: EnvService,
      private toast: ToastController
  ) { }

  async initPush(){
    if(this.plt.is('hybrid')){
      //this.registerPush();
        let permStatus = await PushNotifications.checkPermissions();
        if(permStatus.receive === 'prompt'){
            permStatus = await PushNotifications.requestPermissions();
        }
        if(permStatus.receive === 'granted'){
            await PushNotifications.register();
        }
    }
  }
  initNotifications(){
    if(this.plt.is('hybrid')){
      this.initListener();
    }
  }

  private registerPush(){
    PushNotifications.requestPermissions().then(result => {
      if(result.receive === 'granted'){
        PushNotifications.register();
      }else{
        // NO Permission
      }
    });
    PushNotifications.addListener(
        'registration',
        (token: Token) => {
            this.env.deviceToken(token).subscribe();
            from(Preferences.set({key: 'device_token', value: token.value}));
        });
    PushNotifications.addListener(
        'registrationError',
        (error: any) => {
            alert('Error on registration : '+JSON.stringify(error));
        });
    this.initListener();
  }

  async initListener(){
      await PushNotifications.addListener('registration', token => {
          this.env.deviceToken(token).subscribe();
          from(Preferences.set({key: 'device_token', value: token.value}));
      });

      await PushNotifications.addListener('registrationError', err => {
          alert('Error on registration : '+JSON.stringify(err));
      });

      await PushNotifications.addListener('pushNotificationReceived', notification => {
          if(this.plt.is('android')){
              this.toastNotification(notification);
          }
      });

      await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
          const data = notification.notification.data;
          if(data.page){
              if(data.page == 'prospect' && data.prospect){
                  this.router.navigateByUrl(data.page+'/'+data.prospect);
              }else{
                  this.router.navigateByUrl(data.page);
              }
          }else{
              this.router.navigateByUrl('dashboard');
          }
      });
      /*PushNotifications.requestPermissions().then(result => {
          if(result.receive === 'granted'){
              PushNotifications.addListener(
                  'pushNotificationReceived',
                  async (notification: PushNotificationSchema) => {
                      alert(JSON.stringify(notification));
                      if(this.plt.is('android')){
                        this.toastNotification(notification);
                      }
                  });
              PushNotifications.addListener(
                  'pushNotificationActionPerformed',
                  async (notification: ActionPerformed) => {
                      const data = notification.notification.data;
                      if(data.page){
                          if(data.page == 'prospect' && data.prospect){
                              this.router.navigateByUrl(data.page+'/'+data.prospect);
                          }else{
                              this.router.navigateByUrl(data.page);
                          }
                      }else{
                          this.router.navigateByUrl('dashboard');
                      }
                      //alert('Push action performed : ' + JSON.stringify(notification));

                  });
          }else{
          }
      });*/
  }

  async toastNotification(notification: PushNotificationSchema){
      const toast = await this.toast.create({
          header: notification.title,
          message: notification.body,
          duration: 3000,
          position: 'top'
      });
      toast.present();
  }

  async removePush(){
      const token =  await Preferences.get({ key : 'device_token' });
      this.env.deleteToken(token).subscribe();
      PushNotifications.removeAllDeliveredNotifications();
  }
}
