import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AutoLoginGuard} from "./guards/auto-login.guard";
import {AuthGuard} from "./guards/auth.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canActivate: [ AutoLoginGuard ]
  },
  {
    path: 'repertoire',
    loadChildren: () => import('./pages/repertoire/repertoire.module').then( m => m.RepertoirePageModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'archive',
    loadChildren: () => import('./pages/repertoire/repertoire.module').then( m => m.RepertoirePageModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'prospect/:id',
    loadChildren: () => import('./pages/prospect/prospect.module').then( m => m.ProspectPageModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'profil',
    loadChildren: () => import('./pages/profil/profil.module').then( m => m.ProfilPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
