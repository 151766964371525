import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
//import { Storage } from "@capacitor/storage";
import { map, switchMap, tap } from "rxjs/operators";
import { EnvService } from "./env.service";
import { environment } from "../../environments/environment";
import { Platform } from "@ionic/angular";
import { Router } from "@angular/router";
import { Preferences } from "@capacitor/preferences";

const TOKEN_KEY = 'token';
const MULTICOMPTE_TOKEN_KEY = 'multicompte-token';
const REFRESH_TOKEN_KEY = 'refresh-token';
const REFRESH_TOKEN_EXP_KEY = 'refresh-token-exp';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
  refresh_token = '';
  multicompte_token = '';
  isMulticompteActive = false;

  constructor(
      private http: HttpClient,
      private router: Router
  ) {
    this.loadToken();
  }

    getHttpHeaders(method:string, token?:string){
        let httpOptions;
        if(token){
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                })
            };
        }else{
            httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            };
        }

        return httpOptions;
    }

  async loadToken(){
    let token = await Preferences.get({ key : TOKEN_KEY });
    let multicompte_token = await Preferences.get({ key : MULTICOMPTE_TOKEN_KEY });
      //console.log('token'+token.value);
    const refresh_token_exp = await Preferences.get({ key : REFRESH_TOKEN_EXP_KEY });
    const today = new Date();
    if(parseInt(refresh_token_exp.value) < Math.floor(today.getTime()/1000)){
        const refresh_token = await Preferences.get({ key : REFRESH_TOKEN_KEY });
        this.refresh_token = refresh_token.value;
        await this.refreshToken().subscribe(
            async (res) => {
                token = res;
            },
            async (err) => {}
        );
    }
    if(multicompte_token && multicompte_token.value){
        this.multicompte_token = multicompte_token.value;
    }
    if(token && token.value) {
      this.token = token.value;
      if(this.token != this.multicompte_token){
          this.isMulticompteActive = true;
      }
      this.isAuthenticated.next(true);
    }else{
      this.isAuthenticated.next(false);
    }
  }

  login(credentials: {email, password}): Observable<any> {
      return this.http.post(environment.url_api+'login_check', JSON.stringify(credentials), this.getHttpHeaders('POST')).pipe(
        map((data: any) => data),
        switchMap(data => {
            this.token = data.token;
          from(Preferences.set({key: REFRESH_TOKEN_EXP_KEY, value: data.refresh_token_expiration}));
          from(Preferences.set({key: REFRESH_TOKEN_KEY, value: data.refresh_token}));
          from(Preferences.set({key: MULTICOMPTE_TOKEN_KEY, value: data.token}));
          return from(Preferences.set({key: TOKEN_KEY, value: data.token}));
        }),
        tap(_ => {
          this.isAuthenticated.next(true);
        })
      )
  }
  loginMulticompte(user, pro): Observable<any> {
      return this.http.post(environment.url_api+'multicompte-force-login/'+user.id+'/'+pro.id+'/'+pro.hash, {}, this.getHttpHeaders('POST', this.token)).pipe(
        map((data: any) => data),
        switchMap(data => {
            this.token = data.token;
            //from(Preferences.set({key: REFRESH_TOKEN_EXP_KEY, value: data.refresh_token_expiration}));
            //from(Preferences.set({key: REFRESH_TOKEN_KEY, value: data.refresh_token}));
            return from(Preferences.set({key: TOKEN_KEY, value: data.token}));
        }),
        tap(_ => {
          this.isAuthenticated.next(true);
        })
      )
  }
  logoutMulticompte(): Observable<any> {
      //this.isAuthenticated.next(true);
      console.log(this.multicompte_token);
      return from(Preferences.set({key: TOKEN_KEY, value: this.multicompte_token}));
  }
  refreshToken(): Observable<any> {
      const data = { refresh_token : this.refresh_token};
      return this.http.post(environment.url_api+'token/refresh', JSON.stringify(data), this.getHttpHeaders('POST')).pipe(
        map((data: any) => data),
        switchMap(data => {
            //console.log(data);
            from(Preferences.set({key: REFRESH_TOKEN_EXP_KEY, value: data.refresh_token_expiration}));
            from(Preferences.set({key: REFRESH_TOKEN_KEY, value: data.refresh_token}));
            return from(Preferences.set({key: TOKEN_KEY, value: data.token}));
        }),
        tap(_ => {
            this.isAuthenticated.next(true);
        })
      )
  }

  async logout(): Promise<void> {
      this.isAuthenticated.next(false);
      Preferences.remove({key: TOKEN_KEY});
      this.router.navigateByUrl('/', {replaceUrl: true});
  }

}
