import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from "../services/authentication.service";
import {filter, map, take} from "rxjs/operators";
import {FcmService} from "../services/fcm.service";
import {EnvService} from "../services/env.service";

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanActivate {
  constructor(
      private authService: AuthenticationService,
      private env: EnvService,
      private router: Router,
      private fcm: FcmService
  ) {}

  canActivate(): Observable<boolean>{
    return this.authService.isAuthenticated.pipe(
        filter(val => val !== null),
        take(1),
        map(isAuthenticated => {
          if(isAuthenticated){
              this.env.me().subscribe(
                  async (response) => {
                      if(response.body.connectedUser){
                          this.router.navigateByUrl('/dashboard', { replaceUrl: true });
                      }else{
                          await this.authService.logout();
                          return true;
                      }
                  },
                  async (err) => {
                      await this.authService.logout();
                      return true;
                  }
              );
              //return true;
          }else{
            return true;
          }
        })
    );
  }
}
