import { Component } from '@angular/core';
import { AuthenticationService } from "./services/authentication.service";
import { Router } from "@angular/router";
import {LoadingController, MenuController, Platform} from "@ionic/angular";
import { FcmService } from "./services/fcm.service";
import { EnvService } from "./services/env.service";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Tableau de Bord', url: '/dashboard' },
    { title: 'Répertoire', url: '/repertoire' },
    { title: 'Contact', url: '/contact' },
    { title: 'Mon Compte', url: '/profil' }
  ];

  constructor(
      private menu: MenuController,
      private authService: AuthenticationService,
      private env: EnvService,
      private router: Router,
      private platform: Platform,
      private fcm: FcmService,
      private loadingController: LoadingController
  ) {
    this.initializeApp();
    this.platform.backButton.subscribeWithPriority(0, () => {
      if(this.router.url == '/dashboard' || this.router.url == '/login'){
        navigator['app'].exitApp();
      }
    });
    this.platform.backButton.subscribeWithPriority(5, (processNextHandler) => {
      if(this.router.url == '/profil' || this.router.url == '/contact' || this.router.url == '/repertoire'){
        this.router.navigateByUrl('/dashboard');
      }
      processNextHandler();
    });
  }
  close(){
    this.menu.close();
  }


  initializeApp(){
    this.platform.ready().then(() => {
      //console.log('initialize APP');
      this.fcm.initNotifications();
    })
  }
}
