import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {from, Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {map, switchMap, tap} from "rxjs/operators";
import {AuthenticationService} from "./authentication.service";
import {Token} from "@capacitor/push-notifications";
//import {Storage} from "@capacitor/storage";

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  statuts:any;
  actions:any;
  desengagements:any;
  user:any;
  homepageProspects:any;
  traductions: any;
  constructor(
      private http: HttpClient,
      private authService: AuthenticationService
  ) {
      this.init().subscribe();
  }

  getHttpHeaders(token?:string){
    let httpOptions;
    if(token){
      httpOptions = {
        observe: 'response',
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        })
      };
    }else{
      httpOptions = {
        observe: 'response',
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
    }

    return httpOptions;
  }
    getPatchHttpHeaders(token?:string){
    let httpOptions;
    if(token){
      httpOptions = {
        observe: 'response',
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/merge-patch+json',
          'Authorization': 'Bearer ' + token
        })
      };
    }else{
      httpOptions = {
        observe: 'response',
        withCredentials: true,
        headers: new HttpHeaders({
          'Content-Type': 'application/merge-patch+json'
        })
      };
    }

    return httpOptions;
  }
  getHttpHeadersFile(token?:string){
    let httpOptions;
    if(token){
      httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + token
        })
      };
    }else{
      httpOptions = {
        headers: new HttpHeaders({
        })
      };
    }
    return httpOptions;
  }

  init(): Observable<any> {

    return this.http.get(environment.url_api+'init', this.getHttpHeaders()).pipe(
        tap(response => {
            const datas = response.body;
            this.traductions = datas.traductions;
            this.statuts = Object.keys(datas.statuts).map(key => ({type: key, statut: datas.statuts[key]}));
            this.actions = Object.keys(datas.actions).map(key => ({type: key, action: datas.actions[key]}));
            this.desengagements = Object.keys(datas.perduMotifs).map(key => ({type: key, motif: datas.perduMotifs[key]}));
            return response
        })
    )
  }

  me(params?:any): Observable<any> {
      let url = environment.url_api+'me';
      if(params){
          url = url+params;
      }
    return this.http.get(url, this.getHttpHeaders(this.authService.token)).pipe(
        tap(response => {
            const datas = response.body;
            this.user = datas.connectedUser;
            this.homepageProspects = datas.homepageProspects;
            return response.body;
        })
    )
  }

  uploadBdc(prospect, file, filename){
      //const prospectId = formData.get('prospect').value;
      //alert(JSON.stringify(prospectId));
      const formData = new FormData();
      formData.append('file', file, filename);
      formData.append('name', filename);
      return this.http.post(environment.url_api+'prospects/'+prospect+'/fichierupload', formData, this.getHttpHeadersFile(this.authService.token)).pipe(
        tap(response => {
          return response
        })
    )
  }

    uploadDocument(prospect, file, filename){
        //const prospectId = formData.get('prospect').value;
        //alert(JSON.stringify(prospectId));
        const formData = new FormData();
        formData.append('file', file, filename);
        formData.append('name', filename);
        formData.append('fichierType', '3');
        return this.http.post(environment.url_api+'prospects/'+prospect+'/fichierupload', formData, this.getHttpHeadersFile(this.authService.token)).pipe(
            tap(response => {
                return response
            })
        )
    }
  updateUser(formData): Observable<any> {
    /*return this.http.put(environment.url_api+'users/'+this.user.id, formData, this.getHttpHeadersFile(this.authService.token)).pipe(
        tap(response => {
          return response
        })
    )*/
    //console.log(formData);
    /*const datas = {
        nom: formData.get('nom').value,
        prenom: formData.get('prenom').value,
        email: formData.get('email').value,
        secteurCouvert: formData.get('secteurCouvert').value,
        nombreContactParSemaine: formData.get('nombreContactParSemaine').value,
        technique: formData.get('technique').value,
        delaiMoyen: formData.get('delaiMoyen').value,
        budgetMoyen4x8: formData.get('budgetMoyen4x8').value,
        tel: formData.get('tel').value
    };*/
    const datas = formData.value;
      return this.http.patch(environment.url_api+'users/'+this.user.id, datas, this.getPatchHttpHeaders(this.authService.token)).pipe(
          tap(response => {
              return response
          })
      )
  }
  deviceToken(token: Token): Observable<any> {
    const data = { token: token.value};
    return this.http.post(environment.url_api+'devices', data, this.getHttpHeaders(this.authService.token)).pipe(
        tap(response => {
          return response
        })
    )
  }
  deleteToken(token: Token): Observable<any> {
    const data = { device: token.value};
    return this.http.post(environment.url_api+'devices/deletedevicebytoken', data, this.getHttpHeaders(this.authService.token)).pipe(
        tap(response => {
          return response
        })
    )
  }
  getActions(): Observable<any> {
    return this.http.get(environment.url_api+'actions/all?page=1', this.getHttpHeaders(this.authService.token)).pipe(
        tap(response => {
          return response
        })
    )
  }
  getStatuts(): Observable<any> {
    return this.http.get(environment.url_api+'statuts/all?page=1', this.getHttpHeaders(this.authService.token)).pipe(
        tap(response => {
          return response
        })
    )
  }
  getUser(id): Observable<any> {
    return this.http.get(environment.url_api+'users/'+id, this.getHttpHeaders(this.authService.token)).pipe(
        tap(response => {
          return response
        })
    )
  }
  getContacts(): Observable<any> {
    return this.http.get(environment.url_api+'contacts/all', this.getHttpHeaders(this.authService.token)).pipe(
        tap(response => {
          return response
        })
    )
  }
}
