import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from "@angular/common/http";
import { FormBuilder } from "@angular/forms";

import {CommonModule, registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr'

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [AppComponent],
  imports: [
      CommonModule,
      BrowserModule,
      IonicModule.forRoot(),
      AppRoutingModule,
      HttpClientModule
  ],
  providers: [
      FormBuilder,
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      { provide: LOCALE_ID, useValue: 'fr' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
